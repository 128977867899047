.shopping_title{
    font-size: 20px;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 20px;
}

.plans_container {
    width: 50%;
    margin-left: calc(25%);
    display: grid;
    gap: 20px; /* Space between items */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    padding: 20px; /* Optional: add padding to the container */
    box-sizing: border-box; /* Ensure padding is included in the total width/height */
}

.plans_item {
    padding: 20px;
    border: 3px solid #E8D200;
    background-color: black;
    color: white;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    box-sizing: border-box; /* Ensure padding is included in the total width/height */
    height: fit-content;
    display: flex; /* Enable flexbox on the item itself */
    flex-direction: column; /* Arrange children in a column */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */

    user-select: none; /* Prevent text selection */
}

.plans_item_selected {
    border: 3px solid black;;
    background-color: #E8D200;
    padding: 20px;
    color: black;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    box-sizing: border-box; /* Ensure padding is included in the total width/height */
    height: fit-content;
    display: flex; /* Enable flexbox on the item itself */
    flex-direction: column; /* Arrange children in a column */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */

    user-select: none; /* Prevent text selection */
}

.plan_info{
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}
.plan_detail{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 200;
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

.shopping_modal_container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(80, 80, 80, 0.7);
    display: flex; /* Enable flexbox on the container */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.shopping_modal {
    width: 300px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in the total width/height */
    border-radius: 10px;
}
.shopping_modal_item{
    padding: 10px;
    border-bottom: 1px solid rgb(220, 220, 220);
    cursor: pointer;
}
.shopping_modal_item:hover{
    padding: 10px;
    background-color: #f3f3f3;
    border-bottom: 1px solid rgb(233, 233, 233);
}
.shopping_pay{
    margin-top: 90px;
    display: flex;
    cursor: pointer;
    justify-content: right; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    padding-right: 10px;
    font-weight: 200;
    font-style: italic;
}