.purchase_title{
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}
.purchase_prices{
  width: 90%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 20px;
  margin-left: 5%;
}
.styled-table {
    width: 90%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 20px;
    margin-left: 5%;
  }
  
  .styled-table th,
  .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .styled-table th {
    background-color: #575757;
  }

  .save_dashboard{
    width: fit-content;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid white;
    padding: 5px;
  }
  .save_dashboard:hover{
    width: fit-content;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid white;
    padding: 5px;
    color: #7e6e6e;
    cursor: pointer;
  }

  