.invalid_container{
    height: calc(100vh - 100px);
    width: 80%;
    margin-left: 10%;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    margin-top: 60px;
}