.leaderboard_container{
  margin-top: 100px;
}
.leaderboard_metrics_selection{
  display: flex;
}
.leaderboard_header_item{
  margin-right: 10px;
  cursor: pointer;
}
.leaderboard_heaer_item:hover{
  margin-right: 10px;
  color: blue;
}
.leader_board_table_item{
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    margin-left: calc(5% - 10px);
    justify-content: space-between;
}

.leaderboard_filter{
  height: fit-content;
  border: 1px solid rgb(131, 131, 131);
  margin-left: 5%;
  width: 90%;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .leader_board_table_item{
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    justify-content: space-between;
  }
}