.signout_container{
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    margin-top: 60px;
}

.signout_sub_container{
    width: 500px;
    padding: 20px;
    border: 1px solid rgb(84, 84, 84);
    border-radius: 20px;
}

.signout_title{
    font-size: 20px;
    color: white;
    width: 100%;
    text-align: center;

}

.signout_title.subtitle{
    font-size: 14px;
    font-weight: 200;
}

.signout_input_container{
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-top: 5px;
}

.signout_title_label{
    width: 80%;
    margin-bottom: 10px;
}
.signout_input_item{
    border-radius: 10px;
    width: 80%;
    padding: 8px 5px;
    border: 1px solid white;
}

.signout_button{
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
}

.signout_button_item{
    background-color: #E8D200;
    border: 1px solid #E8D200;
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    width: 120px;
    height: 30px;
    margin-left: 5%;

    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
}

.password{
    width: 70%;
}

.password_toggle_btn{
    width: 5%;
    font-size: 12px;
    margin-left: 5%;
}

.signout_registration_container{
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    text-align: center;
}
.signout_registration_link{
    color: #E8D200;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.error_message_registration{
    color: "red";
    font-size: 12px;
    height: fit-content;
    width: 80%;
    margin: 2px;
    color: rgb(255, 123, 123);
}

/* Media query for devices less than 600px */
@media (min-width: 600px) {
    .signout_button_item{
        margin-left: 25%;
    }
}