.header_container{
    position: fixed;
    background-color: black;
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    top: 0;
    justify-content: space-between;
    padding: 10px;
    z-index: 20;
}

.user_email_header{
    margin-right: 20px;
    color: rgb(194, 194, 194);
}

.header_logo{
    height: 100%;
}
.header_info{
    display: flex;
    margin-right: 15px;
}
.header_shopping_logo{
    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    padding: 8px;
}

.header_shopping_logo_selected{
    cursor: pointer;
    border: 2px solid #E8D200;
    border-radius: 50%;
    padding: 8px;
}
.header_shopping_logo:hover{
    cursor: pointer;
    border: 2px solid rgb(193, 193, 193);
    fill: rgb(193, 193, 193);
}
.header_profile_logo{
    padding: 2px;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 20px;
}
.header_profile_logo:hover{
    padding: 2px;
    cursor: pointer;
    fill: rgb(193, 193, 193);
    background-color: rgb(160, 160, 160);
}

.left_header_elements{
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically centers the divs */
    padding: 0px 10px;
}