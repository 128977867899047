body {
  margin: 0;
  width: 100%;
  color: white;
  background-color: rgb(111, 109, 109);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  user-select: none;
}
.goog-te-gadget img{
  display:none !important;
}
body > .skiptranslate {
  display: none;
}
body {
  top: 0px !important;
}