.profile_body{
    position: relative;
    margin-top: 100px;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
}
.profile_body_info{
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
}

.profile_body p{
    font-size: 30px;
}

.profile_title{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
}
.divider{
    border: 1px solid rgb(134, 134, 134);
    margin-top: 30px;
    margin-bottom: 30px;
}

.profile_form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    width: 90%;
    margin-left: 5%;
}
.profile_form_metrics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 50px;
}
  
  @media (min-width: 600px) {
    .profile_form {
        grid-template-columns: 1fr 1fr;
        width: 50%;
        margin-left: 25%;
        margin-top: 20px;
    }
    .profile_form_metrics {
        grid-template-columns: 1fr 1fr;
        width: 50%;
        margin-left: 25%;
        margin-top: 20px;
    }
  }
  
  .profile_input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .profile_title_label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .profile_input_item {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px);
  }

  .calendar_profile{
    border: 1px solid white;
    display: flex;
    padding: 8px;
    font-size: 14px;
    justify-content: space-between;
    cursor: pointer;
    display: flex;          /* Enable Flexbox */
    align-items: center;   /* Vertically center content */
  }

  .calendar_model_container{
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;          /* Enable Flexbox */
    align-items: center;   /* Vertically center content */
    justify-content: center;
  }
  .calendar_model_container_inner{
    background-color: white;
    color: black;
    height: fit-content;

  }