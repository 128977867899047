.purchase_modal_success{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;          /* Enable Flexbox */
    align-items: center;   /* Vertically center content */
    justify-content: center;
}
.purchase_modal_success_inner{
    background-color: white;
    color: black;
    width: 300px;
    height: fit-content;
    border-radius: 6px;
    padding: 20px;
    text-align: justify;
}
.modal_title{
    font-weight: 500;
}
.modal_subtitle{
    font-weight: 200;
}
.modal_options{
    font-style: italic;
    font-weight: 200;
}
.modal_close{
    background-color: rgb(30, 129, 241);
    color: white;
    width: 50px;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    margin-left: calc(50% - 50px);
    cursor: pointer;
}

@media (max-width: 600px) {
    .purchase_modal_success_inner{
        background-color: white;
        color: black;
        width: 80%;
        height: fit-content;
        border-radius: 6px;
        padding: 20px;
    }
  }