.schedule_container{
  width: 90%;
  margin-left: 5%;
  margin-bottom: 20px;
}
.schedule_user_info{
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.schedule_arrows_container{
  display: flex;
  height: 50px;
  justify-content: space-between;
  font-size: 10px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
}

.grid_container {
  margin-top: 20px;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(6, 1fr); /* 6 columns with equal width */
  gap: 8px; /* 2 * 4px for gap, equivalent to `gap-2` */
}

.schedule_hours_container{
  width: 90%;
  margin-left: 5%;
}
.schedule_hours_container_heading{
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.time_slot{
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 6px;
  background-color: rgb(134, 133, 133);
  border: 2px solid rgb(134, 133, 133);
  display: flex;
  justify-content: space-between;
}
.time_slot_content{
  display: flex;
  justify-content: space-between;

  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
}
.info_text{
  margin-right: 10px;
}
.time_slot_reserved{
  margin-bottom: 5px;
  padding: 10px;
  background-color: rgb(93, 91, 91);
  display: flex;
  justify-content: space-between;
  border: 2px solid #E8D200;
}

.button_text_day_actualday {
  border: 2px solid #E8D200;
  border-radius: 50%;
  padding: 10px;
  width: 10px;            /* Adjust width to fit the content */
  height: 10px;           /* Adjust height to fit the content */
  display: flex;          /* Enable Flexbox */
  align-items: center;    /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  text-align: center;     /* Center text within the content */
}

.button_text_day_actualday_selected{
  border: 2px solid white;
  border-radius: 50%;
  padding: 10px;
  width: 10px;            /* Adjust width to fit the content */
  height: 10px;           /* Adjust height to fit the content */
  display: flex;          /* Enable Flexbox */
  align-items: center;    /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  text-align: center;     /* Center text within the content */
}

.scroll_area{
  margin-top: 30px;
  margin-bottom: 40px;
}

.users_modal{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
}
.users_modal_inner{
  background-color: white;
  z-index: 100;
  color: black;
  margin-top: calc(50%);
  padding: 20px 10px;
  border-radius: 6px;
  width: 80%;
  margin-left: calc(10% - 5px);
}
.users_modal_inner_title{
  width: 100%;
  font-weight: 600;
  text-align: center;
  line-height: 0.5; 
  margin-bottom: 20px;
}

@media (min-width: 700px) {
  .schedule_container{
    width: 500px;
    margin-left: calc(50% - 250px);
    margin-bottom: 20px;
  }
  .schedule_hours_container{
    width: 500px;
    margin-left: calc(50% - 250px);
  }
  .users_modal_inner{
    background-color: white;
    z-index: 100;
    color: black;
    margin-top: calc(50%);
    padding: 10px;
    border-radius: 6px;
    width: 50%;
    margin-left: calc(25% - 5px);
  }
}