.signin_container{
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    margin-top: 60px;
}

.signin_sub_container{
    width: 500px;
    padding: 20px;
    border: 1px solid rgb(84, 84, 84);
    border-radius: 20px;
}

.signin_title{
    font-size: 20px;
    color: white;
    width: 100%;
    text-align: center;
}

.signin_input_container{
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: 20px;
}

.signin_title_label{
    width: 80%;
    margin-bottom: 10px;
}
.signin_input_item{
    border-radius: 10px;
    width: 80%;
    padding: 8px 5px;
    border: 1px solid white;
}

.signin_button{
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
}

.signin_button_item{
    background-color: #E8D200;
    border: 1px solid #E8D200;
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
}

.signin_registration_container{
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the items */
    align-items: center; /* Vertically center the items */
    flex-wrap: wrap;
    text-align: center;
}
.signin_registration_link{
    color: #E8D200;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.signin_recovery_link{
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

    text-decoration: underline;
}

.error_sign_message{
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 12px;
}

/* Media query for devices less than 600px */
@media (max-width: 600px) {
    .signin_sub_container {
        width: 90%; /* Change the width to 100% for smaller screens */
        border: 1px solid rgba(84, 84, 84,0);
    }
}