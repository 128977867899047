.btn_arrow {
  background-color: #E8D200;
  border: 3px solid #E8D200;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.btn_default {
  background-color: white;
  border: 3px solid white;
  padding: 5px;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.btn_secondary {
  background-color: rgb(249, 107, 107);
  border: 3px solid rgb(249, 107, 107);
  padding: 5px;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.btn {
  height: 50px;
  padding: 5px;
  background-color: black;
  border: 3px solid black;
  color: #E8D200;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}
.btn_disabled{
  height: 50px;
  padding: 5px;
  background-color: rgb(128, 128, 128);
  border: 3px solid rgb(128, 128, 128);
  color: black;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}
.btn_reserved{
  height: 50px;
  padding: 5px;
  background-color: black;
  color: #E8D200;;
  border: 3px solid #E8D200;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.selected{
  height: 50px;
  padding: 5px;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 3px solid white;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}
.selected_reserved{
  height: 50px;
  padding: 5px;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 3px solid #E8D200;
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.btn_outline{
  width: 100%;
  padding: 5px;
  color: black;
  top: 95px;
  right: 10px;
  margin-top: 20px;
  background-color: rgba(169, 168, 168,0.6);
  border: 1px solid  rgba(169, 168, 168,0.6);
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.btn_active{
  padding: 5px;
  color: black;
  top: 95px;
  right: 10px;
  width: 100%;
  margin-top: 20px;
  background-color: rgba(232, 210, 0);
  border: 1px solid rgba(232, 210, 0);
  border-radius: 6px;
  display: flex;          /* Enable Flexbox */
  align-items: center;   /* Vertically center content */
  justify-content: center; /* Optionally, horizontally center content */
}

.button_users_list{
  background-color: white;
  border: 1px solid white;
  border-radius: 6px;
  margin-right: 5px;
  cursor: pointer;
}
